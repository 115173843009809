import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useStore } from "vuex";
import figureFormatter from "@/services/utils/figureFormatter";
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const pdfPrinter = () => {
    const store = useStore();
    const { commaFormat } = figureFormatter();
    const { getHeader, getFooter } = pdfHeaderFooter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, ledgerData, contactInfo) => {
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 10, 100, 10, 75 ],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(ledgerData, contactInfo),
            
            styles : {
                header: {
                    fontSize: 24,
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 8,
                font: 'SulaimanLipi'
            },

            info: {
                title: 'Party Ledger'
            }
        }

        pushIntoTable(doc, ledgerData)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (ledgerData, contactInfo) => {
        return [
            {
                text: 'Party Ledger',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 5, 5],
                bold: true,
                color: 'black',
                decoration: 'underline',
                fontSize: 12
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                text: `Party Name & ID: ${contactInfo.full_name} & ${contactInfo.serial_no}`
                            },
                            
                            {
                                text: `Mobile No: ${contactInfo.mobile_no}`
                            },

                            {
                                text: `${ledgerData.accountName ? 'Account Head: ' + ledgerData.accountName : ''}`
                            },

                            {
                                text: `${ledgerData.business ? 'Business: ' + ledgerData.business : ''}`
                            },

                            {
                                text: `${ledgerData.voucherType ? 'Voucher Type: ' + ledgerData.voucherType : ''}`
                            },

                            {
                                text: `${ledgerData.txnType ? 'txn Type: ' + ledgerData.txnType : ''}`
                            },

                            {
                                text: `Report Date: ${ledgerData.startDate} to ${ledgerData.endDate}`
                            }
                        ]
                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                text: `Opening Balance: ${ledgerData.opening_balance
                                        ? commaFormat(ledgerData.opening_balance)
                                        : 0.0}`
                            },

                            {
                                text: `Total Debit: ${ledgerData.total_debit
                                        ? commaFormat(ledgerData.total_debit)
                                        : 0.0}`
                            },

                            {
                                text: `Total Credit: ${ledgerData.total_credit
                                        ? commaFormat(ledgerData.total_credit)
                                        : 0.0}`
                            },

                            {
                                text: `Closing Balance: ${ledgerData.closing_balance
                                        ? commaFormat(ledgerData.closing_balance)
                                        : 0.0}`
                            }
                        ]
                    },
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    body: [],
                    widths: [ '10%', '10%',  '15%', '35%', '10%', '10%', '10%' ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const pushIntoTable = (doc, ledgerData) => {
        let tableItems = ledgerData.ledgers;
        const tableHeader = getTableHeader();
        doc.content[2].table.body.push(tableHeader);
        const openingBalanceRow = getOpeningBalance(ledgerData)
        doc.content[2].table.body.push(openingBalanceRow);
        tableItems.forEach(item => {
            const rowData = getRowData(item);
            return doc.content[2].table.body.push(rowData);
        });
        const getLastRow = getTotal(ledgerData)
        doc.content[2].table.body.push(getLastRow);
    }

    const getOpeningBalance = (ledgerData) => {
        const lastRow = [
            {
                text: 'Opening Balance',
                alignment: 'right',
                colSpan: 4
            },
             '', '', '','','',
            {
                text: ledgerData.opening_balance,
                alignment: 'right'
            }
        ]
        return lastRow
    }

    const getTotal = (ledgerData) => {
        const lastRow = [
            {
                text: 'Closing Balance',
                alignment: 'right',
                colSpan: 4
            },
              '', '', '',
            {
                text: ledgerData.total_debit,
                alignment: 'right'
            },
            {
                text: ledgerData.total_credit,
                alignment: 'right'
            },
            {
                text: ledgerData.closing_balance,
                alignment: 'right'
            }
        ]
        return lastRow
    }

    const getTableHeader = () => {
        const tableHeader = [
            { text: 'Date' },
            { text: 'Txn Type' },
            { text: 'Voucher No' },
            { text: 'Accounts Head' },
            { text: 'Debit', alignment: 'right' },
            { text: 'Credit', alignment: 'right' },
            { text: 'Balance', alignment: 'right' }
        ]

        return tableHeader
    }
    const getRowData = (item) => {
        const isNullOrZero = (value) =>
            value == null || value === 0 || value === '0.00';
        let accountHeadName = '';
        if (isNullOrZero(item.debit)) {
            accountHeadName = item.debit_account_head;
        }
        if (isNullOrZero(item.credit)) {
            accountHeadName = item.credit_account_head;
        }

        const tableRow = [ 
            { text: item.date },
            { text: item.txn_type },
            { text: item.voucher_no },
            { text: accountHeadName },
            { text: commaFormat(item.debit), alignment: 'right' },
            { text: commaFormat(item.credit), alignment: 'right' },
            { text: commaFormat(item.balance), alignment: 'right' }
        ] 

        return tableRow
    }

    return {
        exportToPDF
    }

}

export default pdfPrinter;